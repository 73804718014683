export default {
  data() {
    return {
      loadingRegions: false,
      resultRegions: [],
    };
  },
  methods: {
    async changeRegions(id) {
      this.loadingRegions = true;
      await this.fetchRegions(id);
      this.resultRegions = this.regions;
      this.loadingRegions = false;
    },
  },
};
