<template>
  <div v-if="!loadingGov">
    <v-tour :options="tourOptions" name="addOrder" :steps="steps"></v-tour>
    <div class="title-page flex justify-between text-center">
      <div>
        <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
        <h3
          class="font-bold mb-2 ml-3"
          style="display: inline-block; font-size: 1.125rem"
        >
          {{ $t("addOrder") }}
        </h3>
      </div>
    </div>
    <div class="line"></div>
    <div data-order-tour-step="1">
      <div class="p-6 bg-white form-inputs">
        <div class="first-input">
          <!-- sender -->
          <label class="font-bold">
            {{ $t("sender") }} :({{ $t("optional") }})
          </label>
          <input
            v-model="orderData.second_client"
            maxlength="100"
            v-validate="'min:3'"
            name="sender"
            type="text"
            class="input"
            :placeholder="$t('sender')"
          />
          <!-- <span style="font-size: 0.7rem" class="text-danger err-msg">{{
            errors.first("sender")
          }}</span> -->
        </div>
        <div class="first-input">
          <!-- receiver -->
          <label class="font-bold">
            {{ $t("receiver") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <input
            v-model="orderData.receiver"
            name="receiver"
            maxlength="100"
            v-validate="'required|min:3'"
            type="text"
            class="input"
            :placeholder="$t('exampleMainBranch')"
          />
          <span style="font-size: 0.7rem" class="text-danger err-msg">{{
            errors.first("receiver")
          }}</span>
        </div>
        <div class="first-input">
          <!-- number1 -->
          <label class="font-bold">
            {{ $t("number1") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <vue-tel-input
            v-model="orderData.phone1"
            mode="international"
            :inputOptions="internationalInputOptions"
            :validCharactersOnly="true"
            @validate="phoneObject($event, 'phone1')"
            :autoFormat="true"
            name="number"
            v-validate="'required'"
          ></vue-tel-input>
          <span style="font-size: 0.7rem" class="text-danger err-msg"
            >{{ errors.first("number")
            }}<span v-if="!this.validationsPhone.phone1 && orderData.phone1"
              >يجب ادخال رقم هاتف صحيح</span
            >
          </span>
        </div>
        <div class="second-input">
          <!-- number2 -->
          <label class="font-bold">
            {{ $t("number2") }} :({{ $t("optional") }})
          </label>
          <vue-tel-input
            v-model="orderData.phone2"
            mode="international"
            :inputOptions="internationalInputOptions"
            :validCharactersOnly="true"
            @validate="phoneObject($event, 'phone2')"
            name="number2"
          ></vue-tel-input>
          <!-- <span style="font-size: 0.7rem" class="text-danger err-msg"
            >{{ errors.first("number2") }}
            </span
            > -->
            <span style="font-size: 0.7rem" class="text-danger err-msg" v-if="!this.validationsPhone.phone2"
              >يجب ادخال رقم هاتف صحيح</span>
        </div>
        <div class="second-input">
          <!-- numbreTracking -->
          <label class="font-bold">
            {{ $t("numbreTracking") }} :({{ $t("optional") }})
          </label>
          <vue-tel-input
            v-model="orderData.api_followup_phone"
            mode="international"
            :inputOptions="internationalInputOptions"
            :validCharactersOnly="true"
            @validate="phoneObject($event, 'api_followup_phone')"
            name="api_followup_phone"
          ></vue-tel-input>
          <!-- <span style="font-size: 0.7rem" class="text-danger err-msg"
            >{{ errors.first("mobileFollow")
            }}
            </span
          > -->
          <span style="font-size: 0.7rem" class="text-danger err-msg" v-if="!this.validationsPhone.api_followup_phone"
              >يجب ادخال رقم هاتف صحيح</span
            >
        </div>
        <div class="second-input">
          <label class="label-select font-bold" ref="govLabel"
            >{{ $t("government") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <SelectInputVue
            :options="resultGovs"
            v-model="orderData.government_id"
            @input="changeRegions(orderData.government_id)"
            v-validate="'required'"
            name="government"
          >
            <template #search>
              <input
                @blur.stop
                class="inner-input"
                v-model="search"
                @input="searchGov()"
                placeholder="إبحث هنا"
              />
            </template>
          </SelectInputVue>
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{
            errors.first("government")
          }}</span>
        </div>
        <div class="second-input">
          <label class="label-select font-bold" ref="govLabel"
            >{{ $t("area") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            ></label
          >
          <div v-if="!loadingRegions">
            <SelectInputVue
              :options="resultRegions"
              v-model="orderData.area_id"
              name="area"
              v-validate="'required'"
            >
              <template #search>
                <input
                  @blur.stop
                  class="inner-input"
                  v-model="searchAreaWord"
                  @input="searchArea()"
                  placeholder="إبحث هنا"
                />
              </template>
            </SelectInputVue>
            <span style="font-size: 0.7rem" class="text-danger font-bold">{{
              errors.first("area")
            }}</span>
          </div>
          <SkeletonLoadingVue v-else />
        </div>
        <div class="third-input">
          <!-- address -->
          <label class="font-bold">
            {{ $t("address") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            >
          </label>
          <input
            :disabled="orderData.is_office_given"
            v-model="orderData.address"
            type="text"
            maxlength="100"
            name="address"
            v-validate="'required|min:3'"
            class="input"
            :placeholder="$t('exampleAddress')"
          />
          <span style="font-size: 0.7rem" class="text-danger err-msg">{{
            errors.first("address")
          }}</span>
        </div>
        <div class="third-input">
          <!-- notes -->
          <label class="font-bold">
            {{ $t("notes") }} :({{ $t("optional") }})
          </label>
          <input
            maxlength="250"
            minlength="3"
            v-model="orderData.notes"
            type="text"
            class="input"
            :placeholder="$t('exampleNotes')"
          />
          <!-- <span style="font-size: 0.7rem" class="text-danger">{{}}</span> -->
        </div>
        <div class="four-input">
          <!-- collectedAmount -->
          <label class="font-bold">
            {{ $t("collectedAmount") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            >
          </label>
          <input
            v-validate="'decimal|required'"
            @paste="remvoeSpace('orderData', 'amount_to_be_collected')"
            name="collectedAmount"
            ref="collectedAmount"
            maxlength="20"
            v-model="orderData.amount_to_be_collected"
            type="text"
            class="input"
            :placeholder="$t('exampleAmountOrValue')"
          />
          <span style="font-size: 0.7rem" class="text-danger err-msg">{{
            errors.first("collectedAmount")
          }}</span>
        </div>
        <div class="four-input">
          <!-- returnValue -->
          <label class="font-bold">
            {{ $t("returnValue") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            >
          </label>
          <input
            v-validate="'numeric|required'"
            @paste="remvoeSpace('orderData', 'return_amount')"
            name="returnValue"
            ref="returnValue"
            maxlength="20"
            v-model="orderData.return_amount"
            type="text"
            class="input"
            :placeholder="$t('exampleAmountOrValue')"
          />
          <span style="font-size: 0.7rem" class="text-danger err-msg">{{
            errors.first("returnValue")
          }}</span>
        </div>
        <div class="four-input">
          <!-- invoiceNumber -->
          <label class="font-bold">
            {{ $t("invoiceNumber") }} :({{ $t("optional") }})
          </label>
          <input
            v-model="orderData.invoice_number"
            class="input"
            :placeholder="$t('exampleInvoice')"
          />
          <!-- <span style="font-size: 0.7rem" class="text-danger">{{}}</span> -->
        </div>
        <div class="four-input">
          <!-- contentOrder -->
          <label class="font-bold">
            {{ $t("contentOrder") }} :
            <span style="font-size: 1.2rem" class="text-danger font-bold"
              >*</span
            >
          </label>
          <input
            maxlength="100"
            v-model="orderData.order_summary"
            type="text"
            class="input"
            name="contentOrder"
            v-validate="'required|min:3'"
            :placeholder="$t('exampleContentOrder')"
          />
          <span style="font-size: 0.7rem" class="text-danger err-msg">{{
            errors.first("contentOrder")
          }}</span>
        </div>
        <!-- Do given order -->
        <p class="do-given font-bold">{{ $t("willOrderBe") }}</p>
        <div
          class="w-full flex-wrap"
          style="display: flex; align-items: center; grid-column: span 4"
        >
          <span
            style="display: flex; align-items: center"
            class="mt-2 mr-2"
            :key="item.id"
            v-for="item in payload"
          >
            <span class="checked" v-if="orderData.is_order == item.id">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  id="Icon_material-check-circle"
                  data-name="Icon material-check-circle"
                  d="M12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3ZM10.2,16.5,5.7,12l1.269-1.269L10.2,13.953l6.831-6.831L18.3,8.4Z"
                  transform="translate(-3 -3)"
                  fill="#31006F"
                />
              </svg>
            </span>
            <span
              class="unchecked"
              v-if="orderData.is_order != item.id"
              @click="orderData.is_order = item.id"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.92"
                height="17.92"
                viewBox="0 0 17.92 17.92"
              >
                <path
                  id="Icon_material-radio-button-unchecked"
                  data-name="Icon material-radio-button-unchecked"
                  d="M11.96,3a8.96,8.96,0,1,0,8.96,8.96A8.963,8.963,0,0,0,11.96,3Zm0,16.128a7.168,7.168,0,1,1,7.168-7.168A7.166,7.166,0,0,1,11.96,19.128Z"
                  transform="translate(-3 -3)"
                />
              </svg>
            </span>
            <input
              style="cursor: pointer"
              :value="item.id"
              v-model="orderData.is_order"
              :id="item.id"
              class="custom-control-primary mr-1"
              type="radio"
              name="radiosGroup"
            />
            <label
              style="cursor: pointer; font-size: 0.875rem; margin-left: 1.5rem"
              :style="[
                orderData.is_order == item.id
                  ? { color: '#31006F' }
                  : { color: '#6E6B7B' },
              ]"
              :for="item.id"
              >{{ item.name }}</label
            >
          </span>
        </div>
        <div style="display: flex; align-items: center; grid-column: span 3">
          <form>
            <vs-checkbox
              name="checkbox"
              v-model="orderData.is_office_given"
              class="remember m-0"
              >{{ $t("doYouWantDeliverOrderNearstBranch") }}</vs-checkbox
            >
          </form>
        </div>
        <div style="display: flex; align-items: center; grid-column: span 2">
          <form>
            <vs-checkbox
              name="checkbox"
              v-model="orderData.can_open"
              class="remember m-0"
              >يمكن فتح الشحنة ؟</vs-checkbox
            >
          </form>
        </div>
        <!-- <div style="display: flex; align-items: center; grid-column: span 2">
          <form>
            <vs-checkbox
              name="checkbox"
              v-model="orderData.has_packaging_bag"
              class="remember m-0"
              >تغليف الطرد ؟</vs-checkbox
            >
          </form>
        </div> -->
        <!-- Return Summry -->
        <div
          v-if="orderData.is_order != 0"
          class="w-full"
          style="grid-column: span 12"
        >
          <label class="font-bold"> {{ $t("contentReturn") }} </label>
          <vs-input
            maxlength="100"
            minlength="3"
            v-model="orderData.return_summary"
            class="w-full"
            type="text"
            v-validate="'required'"
          />
        </div>

        <!-- Do you want add new order -->
        <div style="grid-column: span 12">
          <form class="w-full flex items-center justify-start">
            <vs-checkbox name="checkbox" v-model="addNew" class="remember m-0"
              >هل تريد إضافة طرد أخر ؟</vs-checkbox
            >
          </form>
        </div>
        <addProductsSection
          :edit="false"
          ref="products"
          v-if="have_stock === 1"
        />
      </div>
      <div
        class="flex flex-wrap items-center p-6 justify-end footer"
        slot="footer"
      >
        <button
          class="mr-6 send-order"
          :class="{ 'opacity-btn1': !isFormValid }"
          @click="submitData(4)"
          :disabled="!isFormValid"
        >
          {{
            loading && orderData.status == 4 ? "جاري الارسال ..." : $t("send")
          }}
        </button>
        <button
          class="mr-6 save-order"
          :class="{ 'opacity-btn': !isFormValid }"
          @click="submitData(1)"
          :disabled="!isFormValid"
        >
          {{ loading && orderData.status == 1 ? "جاري الحفظ ..." : $t("save") }}
        </button>
        <router-link to="/">
          <button class="mr-6 back-order">{{ $t("back") }}</button>
        </router-link>
      </div>
    </div>
  </div>
  <div class="flex justify-center items-center" style="height: 70vh" v-else>
    <SpinnerColor2Vue />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import tour from "@/mixins/tour.js";
import SpinnerColor2Vue from "../components/table-components/SpinnerColor2.vue";
import notifyState from "../../mixins/notifyState";
import landLine from "@/mixins/landLine.js";
import addProductsSection from "../products/SelectProductsSection.vue";
import removeSpaceNumber from "@/mixins/removeSpaceNumber.js";
import SelectInputVue from "../../layouts/components/SelectInput.vue";
import SkeletonLoadingVue from "@/layouts/components/SkeletonLoading.vue";
import regionsMixins from "@/mixins/regions.js";
import validationPhone from "@/mixins/validationPhone.js";
import searchReagions from "@/mixins/searchRegions.js";
export default {
  mixins: [
    tour,
    notifyState,
    landLine,
    removeSpaceNumber,
    regionsMixins,
    validationPhone,
    searchReagions,
  ],
  data() {
    return {
      orderData: {
        second_client: "",
        return_amount: null,
        receiver: "",
        api_followup_phone: "",
        phone1: "",
        phone2: "",
        government_id: null,
        return_summary: "",
        area_id: null,
        is_order: "",
        address: "",
        amount_to_be_collected: null,
        invoice_number: null,
        order_summary: "",
        notes: "",
        is_office_given: false,
        status: null,
        can_open: 1,
        // has_packaging_bag: 0,
      },
      search: "",
      resultGovs: "",
      addNew: false,
      loading: false,
      status: undefined,
      steps: [
        {
          target: '[data-order-tour-step="1"]', // We're using document.querySelector() under the hood
          header: {
            title: "تفاصيل الطرد",
          },
          content: `في خانة الراسل الفرعي يفضل اضافة اسم الراسل الفعلي للطرد لسهولة عملية التسليم`,
          params: {
            placement: "top",
            enableScrolling: false,
          },
        },
        {
          target: '[data-tour-step="8"]',
          header: {
            title: "متابعة المحادثات",
          },
          content: `يمكنك متابعة المحادثات الخاصة بك من هنا (الطرود / المهمات / تذاكر الدعم)`,
        },
      ],
      loadingGov: false,
    };
  },
  computed: {
    ...mapGetters("dataList", ["governments", "payload", "order_code"]),
    ...mapGetters("userData", ["have_stock"]),
    ...mapState("externalApi", ["regions"]),
    isFormValid() {
      return !this.errors.any() && this.orderData.government_id > 0;
    },
  },
  watch: {
    "orderData.is_office_given": {
      handler(val) {
        if (val) {
          this.orderData.address = "تسليم مكتب";
        } else {
          this.orderData.address = "";
        }
      },
    },
  },
  components: {
    SpinnerColor2Vue,
    addProductsSection,
    SelectInputVue,
    SkeletonLoadingVue,
  },
  methods: {
    ...mapActions("dataList", ["fetchGovData", "postOrder"]),
    ...mapActions("externalApi", ["fetchRegions"]),
    initValues() {
      Object.keys(this.orderData).forEach((key) => {
        this.orderData[key] = "";
      });
      this.orderData.is_office_given = false;
      this.addNew = false;
    },
    searchGov() {
      if (this.search == "") {
        this.resultGovs = this.governments;
      } else {
        this.resultGovs = this.governments.filter((governments) =>
          governments.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },
    submitData(status) {
      this.$validator.validateAll().then(async (result) => {
        if (this.orderData.is_order == 0) {
          this.orderData.return_summary = "";
        }
        // if (this.orderData.has_packaging_bag)
        //   this.orderData.has_packaging_bag = 1;
        if (result && this.validPhone) {
          if (this.have_stock === 1) {
            let products = [...this.$refs.products.slectedProducts];
            products = products.filter((product) => product.selected == true);
            if (products.length) {
              this.orderData.order_products = products;
            }
          }
          this.loading = true;
          this.orderData.status = status;
          this.formatNumberPhone("orderData", "phone1");
          this.formatNumberPhone("orderData", "phone2");
          this.formatNumberPhone("orderData", "api_followup_phone");
          await this.postOrder(this.orderData);
          this.loading = false;
          if (this.$store.state.dataList.successApi == 1) {
            this.notifySuccess("addToast");
          } else {
            this.notifyFaild("faildAdd");
          }
          if (!this.addNew) {
            this.$router.push({ path: `/OrderDetails/${this.order_code}` });
          }
          this.initValues();
        }
      });
    },
  },
  async created() {
    this.loadingGov = true;
    if (this.governments.length == 0) {
      await this.fetchGovData();
    }
    this.resultGovs = this.governments;
    this.loadingGov = false;
  },
  mounted() {
    if (this.runWalkthrough()) {
      localStorage.setItem("addOrderTour", false);
      // this.startTour("addOrder");
      setTimeout(() => {
        window.scrollTo(0, 20);
      }, 500);
      this.$watch(
        () => {
          return this.$tours.addOrder.currentStep;
        },
        (val) => {
          if (val == 1) {
            const nextStep = document.querySelector(".v-step__button");
            nextStep.addEventListener("click", () => {
              this.$router.push({ path: "/apps/chat/id", query: { type: 3 } });
            });
          }
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_addOrder.scss";
@import "@/assets/scss/vuexy/_product.scss";
</style>

<style lang="scss">
.form-inputs {
  .vue-tel-input {
    .vti__input {
      direction: rtl;
    }
  }
}

.second-input {
  .con-select {
    label {
      font-size: 0.875rem;
      color: #212121;
      line-height: 1.3125rem;
      margin-bottom: 0.4375rem;
      display: block;
    }
    width: 100%;
    margin: 0rem;
    .input-select-con {
      width: 100%;
      input {
        width: 100%;
        font-family: "Cairo";
        font-size: 0.75rem;
        height: 2.4375rem;
      }
      input::placeholder {
        color: #6e6b7b;
        opacity: 50%;
        font-size: 0.75rem;
        line-height: 1.3125rem;
        font-family: "cairo";
      }
    }
  }
}
.input-select {
  width: 100%;
}
.vs-select--options {
  ul {
    li {
      button {
        span {
          font-family: "cairo";
        }
      }
    }
  }
}
.custom-select {
  .inner-input {
    margin-bottom: 0px;
  }
}
.product {
  .vs-checkbox-primary .vs-checkbox {
    border: 2px solid #fff !important;
    width: 20px;
    height: 20px;
    margin-right: 11px;
    border-radius: 4px;
  }
  .con-slot-label {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
  }
}
</style>
