export default {
  methods: {
    landLine(data) {
      if (data) {
        let text = Array.from(data);
        if (text[1] == 1) return 11;
        else return 10;
      }
    },
  },
};
