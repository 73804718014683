export default {
  data() {
    return {
      copiedText: undefined,
    };
  },
  methods: {
    convertNumberToArabic(model, model2) {
      if (model2)
        this[model][model2] = this.convertNumbers2English(this[model][model2]);
      else this[model] = this.convertNumbers2English(this[model]);
    },
    convertNumbers2English: function (string) {
      return string
        .replace(/[\u0660-\u0669]/g, function (c) {
          return c.charCodeAt(0) - 0x0660;
        })
        .replace(/[\u06f0-\u06f9]/g, function (c) {
          return c.charCodeAt(0) - 0x06f0;
        });
    },
    async remvoeSpace(model, model2) {
      await navigator.clipboard.readText().then((text) => {
        this.copiedText = text;
        return text;
      });
      let copied = this.copiedText.replace(/\s+/g, "");
      copied = copied.replace(/[^a-zA-Z0-9 ]/g, "");
      copied = this.convertNumbers2English(copied);
      if (copied.length === 12) copied = copied.slice(1);
      if (model2) this[model][model2] = copied;
      else this[model] = copied;
    },
  },
};
