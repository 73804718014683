export default {
  data() {
    return {
      validPhone: false,
      internationalInputOptions: {
        placeholder: "",
        // showDialCode: true,
      },
      validationsPhone: {
        phone1: true,
        phone2: true,
        api_followup_phone: true,
      },
      iso2: undefined,
    };
  },
  methods: {
    phoneObject(object, variable) {
      if (Object.keys(object).length !== 0) {
        if (object.valid) {
          this.validationsPhone[variable] = true;
          this.validPhone = true;
        } else {
          this.validationsPhone[variable] = false;
          this.validPhone = false;
        }
      }
    },
    returnDefaultCountry(phone) {
      let code = phone;
      if (code) {
        if (code.startsWith(966)) {
          return 966;
        } else {
          return 20;
        }
      }
    },
    formatNumberPhone(data, phone) {
      if (this[data][phone]) {
        if (this[data][phone].includes("+2")) {
          this[data][phone] = this[data][phone].replace("+2", "");
          this[data][phone] = this[data][phone].replace(/\s/g, "");
        }
      }
    },
  },
};
