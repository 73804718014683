<template>
  <div class="products mt-5" v-if="!loadProducts && canEditProduct">
    <h5>{{ edit ? "تعديل المنتج" : "إضافة المنتج" }}</h5>
    <div class="search-product">
      <input
        type="text"
        placeholder="ابحث بإسم المنتج او كود SKU"
        v-model="keywordProduct"
        @keyup.enter="searchProduct()"
      />
      <svg
        v-if="!loadSearchProduct"
        xmlns="http://www.w3.org/2000/svg"
        width="14.176"
        height="14.329"
        viewBox="0 0 10.176 10.329"
      >
        <g id="search" transform="translate(-2.8 -2.561)">
          <circle
            id="Ellipse_274"
            data-name="Ellipse 274"
            cx="3.844"
            cy="3.844"
            r="3.844"
            transform="translate(3.3 3.061)"
            fill="none"
            stroke="#b9b9c3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <line
            id="Line_69"
            data-name="Line 69"
            x1="2.222"
            y1="2.222"
            transform="translate(10.046 9.96)"
            fill="none"
            stroke="#b9b9c3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </g>
      </svg>
      <div v-else class="load-search"><SpinnerColor2Vue /></div>
    </div>
    <div class="product">
      <form class="w-full">
        <div
          class="inner-product"
          v-for="(product, index) in filterAllProducts"
          :style="!product.selected ? { opacity: 0.5 } : ''"
          :key="index"
        >
          <div
            @click="selectProduct(product)"
            style="padding: 8px"
            class="flex items-center"
          >
            <input
              type="checkbox"
              class="checkbox"
              :checked="product.selected"
            />
            <span class="checkmark"></span>
            <div>
              <h6>{{ product.name }}</h6>
              <h6 class="sku">{{ product.sku }}</h6>
            </div>
          </div>
          <div class="qut">
            <span>الكمية</span>
            <div class="inner-qut">
              <svg
                @click="changeQut(product, 1)"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="number">{{ product.quantity }}</span>
              <svg
                @click="changeQut(product, 2)"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="add-more" @click="loadMord()" v-if="showLoadMore">
      <p v-if="!loadMoreProduct">مزيد المنتجات +</p>
      <spinner-color-2-vue v-else />
    </div>
  </div>
  <div
    class="products mt-5"
    v-else-if="!loadProducts && !canEditProduct && getSelectedProducts.length"
  >
    <h5>المنتجات</h5>
    <div class="product">
      <form class="w-full">
        <div
          class="inner-product"
          v-for="(product, index) in getSelectedProducts"
          :key="index"
        >
          <div style="padding: 8px" class="flex items-center">
            <h6>{{ product.name }}</h6>
          </div>
          <div class="qut">
            <span>الكمية</span>
            <div class="inner-qut">
              <span class="number">{{ product.quantity }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="products" v-else-if="loadProducts">
    <spinner-color-2-vue />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SpinnerColor2Vue from "../components/table-components/SpinnerColor2.vue";
export default {
  data() {
    return {
      allProducts: [],
      slectedProducts: [],
      loadProducts: false,
      loadMoreProduct: false,
      userProducts: "",
      keywordProduct: "",
      loadSearchProduct: false,
      search: false,
      newProducts: [],
      searchProducts: [],
      pageSearch: 1,
    };
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    canEditProduct: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SpinnerColor2Vue,
  },
  computed: {
    ...mapGetters("products", [
      "products",
      "next_products_url",
      "new_products",
      "next_products_url_search",
    ]),
    ...mapGetters("dataList", ["order_details"]),
    getNewProducts() {
      this.newProducts = JSON.parse(JSON.stringify(this.new_products));
      return this.newProducts;
    },
    filterAllProducts() {
      if (this.keywordProduct != "" && this.search) {
        return this.searchProducts;
      } else {
        this.search = false;
        return this.allProducts;
      }
    },
    showLoadMore() {
      if (this.keywordProduct != "" && this.search) {
        if (this.next_products_url_search != null) {
          return true;
        }
        return false;
      } else {
        if (this.next_products_url != null) {
          return true;
        }
        return false;
      }
    },
    getSelectedProducts() {
      return this.slectedProducts;
    },
  },
  methods: {
    ...mapActions("products", ["fetchProducts", "fetchSearchProducts"]),
    findIndexProduct(product, products) {
      return this[products].findIndex((p) => p.id == product.id);
    },
    selectProduct(product) {
      if (!product.selected) {
        product.selected = true;
        if (this.findIndexProduct(product, "slectedProducts") < 0)
          this.slectedProducts.push(product);
      } else {
        product.selected = false;
        this.slectedProducts.splice(
          this.findIndexProduct(product, "slectedProducts"),
          1
        );
      }
      this.$set(
        this.allProducts,
        this.findIndexProduct(product, "allProducts"),
        product
      );
    },
    changeQut(product, type) {
      if (
        product.selected &&
        (product.is_archived == 0 || product.client_product.is_archived == 0)
      ) {
        switch (type) {
          case 1:
            product.quantity++;
            break;

          case 2:
            if (product.quantity > 1) product.quantity--;
            break;
        }
        this.$set(
          this.slectedProducts,
          this.findIndexProduct(product, "slectedProducts"),
          product
        );
      }
    },
    loadMord() {
      if (this.keywordProduct != "" && this.search)
        this.loadMoreSearchProducts();
      else this.loadMordProducts();
    },
    async loadMoreSearchProducts() {
      this.loadMoreProduct = true;
      this.pageSearch++;
      await this.fetchSearchProducts({
        keyword: this.keywordProduct,
        page: this.pageSearch,
      });
      this.searchProducts = [...this.searchProducts, ...this.getNewProducts];
      this.loadMoreProduct = false;
    },
    async loadMordProducts() {
      this.loadMoreProduct = true;
      await this.fetchProducts({ isAppend: false, paginate: 4 });
      this.getNewProducts;
      if (this.edit) {
        this.userProducts.filter((p) => {
          return (this.newProducts = this.newProducts.filter((product) => {
            return p.product_id !== product.product_id;
          }));
        });
      }
      this.allProducts.push(...this.newProducts);
      this.loadMoreProduct = false;
    },
    editedProducts() {
      if (this.edit) {
        this.userProducts = JSON.parse(
          JSON.stringify(this.order_details)
        ).order_products;
        this.userProducts = this.userProducts.map((product) => {
          product.product_id = product.client_product_id;
          product.name = product.client_product.name;
          product.sku = product.client_product.sku;
          product.selected = true;
          return product;
        });
        this.userProducts.filter((p) => {
          return (this.allProducts = this.allProducts.filter((product) => {
            return p.product_id !== product.product_id;
          }));
        });
        this.allProducts.unshift(...this.userProducts);
        this.slectedProducts = this.userProducts;
      }
    },
    async searchProduct() {
      this.loadSearchProduct = true;
      await this.fetchSearchProducts({
        keyword: this.keywordProduct,
        page: this.pageSearch,
      });
      this.searchProducts = this.getNewProducts;
      this.search = true;
      this.getNewProducts;
      this.loadSearchProduct = false;
    },
  },
  async created() {
    this.loadProducts = true;
    if (this.products.length == 0) {
      await this.fetchProducts({ isAppend: true, paginate: 4 });
    }
    this.allProducts = JSON.parse(JSON.stringify(this.products));
    this.editedProducts();
    this.loadProducts = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_product.scss";
</style>
