export default {
  data() {
    return {
        resultRegions: "",
        searchAreaWord: "",
    };
  },
  methods: {
    searchArea() {
        if (this.searchAreaWord == "") {
          this.resultRegions = this.regions;
        } else {
          this.resultRegions = this.regions.filter((regions) =>
            regions.name.toLowerCase().includes(this.searchAreaWord.toLowerCase())
          );
        }
    },
  },
};
